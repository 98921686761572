import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoSm from "../../../assets/icons/logo-1.jpg";
import { GenerateOTPResetPasswordService, ValidateOTPResetPasswordService } from "../../../services/user";
import "../auth-common.css";

const OtpValidation = () => {
    const [formData, setFormData] = useState({
        otp: "",
    });
    const [formErrors, setFormErrors] = useState({
        otp: ""
    });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state) {
            navigate('/');
        }
    }, [location, navigate]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        value = value.replace(/[^0-9]/g, '');
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let temp_validate_otp = await ValidateOTPResetPasswordService(location.state.mobile, formData.otp, '', true);
        if (temp_validate_otp.success) {
            navigate("/forgot-password/validate", { state: { mobile: location.state.mobile } });
        } else {
            setFormErrors({
                otp: temp_validate_otp.message,
            });
        }
        setLoading(false);
    };

    const handleResend = async (e) => {
        e.preventDefault();
        setFormErrors({
            otp: "",
        });
        setFormData({
            otp: "",
        });
        setLoading(true);
        let temp_generate_otp = await GenerateOTPResetPasswordService(location.state.mobile);
        if (!temp_generate_otp.success) {
            setFormErrors({
                otp: temp_generate_otp.message,
            });
        }
        setLoading(false);
    }

    return (
        <div className="forgot-pswd-container auth-container-main">
            <div className="wrapper-side form-container">
                <div className="form-container">
                    <div className="logo-wrapper">
                        <img src={logoSm} alt="" />
                    </div>
                    <h1 className="auth-title">Verification</h1>
                    <form onSubmit={handleSubmit} className="auth-form signup" >
                        <div className="form-group-dx">
                            <label style={{ marginBottom: "10px" }}><b>Mobile:</b> {location.state.mobile}</label>
                            <label htmlFor="otp">We've sent OTP to your mobile. Please enter 6 digits code you receive.</label>
                            <input
                                type="text"
                                id="otp"
                                name="otp"
                                value={formData.otp}
                                placeholder="Enter otp"
                                onChange={handleInputChange}
                                required
                                maxLength="6"
                            />
                        </div>
                        {formErrors.otp && (
                            <p className="dx-lib input-error">{formErrors.otp}</p>
                        )}
                        <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                            {loading ? "Validating OTP..." : "Validate OTP"}
                        </button>
                    </form>
                    {formErrors.otp && (
                        <div className="resend-otp">
                            Didn't receive code? <button className="btn-link" onClick={handleResend}>Resend</button>
                        </div>
                    )}
                    <p className="account-exist-check">
                        Remember password? <Link to="/">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OtpValidation;