import React from 'react';
import loadingGif from "../../assets/images/loading.gif";
import "./loading.css";

const Loading = () => {
    return (
        <div className="loding-container">
            <img src={loadingGif} alt="loading..." />
        </div>
    )
}

export default Loading;