import React from "react";
import { CgProfile } from "react-icons/cg";
import { IoMdBusiness, IoMdLogOut } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { RiCloseLargeFill } from "react-icons/ri";
import { TbHistory } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoSm from "../../assets/icons/logo-1.jpg";
import { signOut } from "../../redux/user/userSlice";
import "./sidebar.css";

const Sidebar = ({ isOpen, toggleSidebar }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = (e) => {
        e.preventDefault();
        dispatch(signOut());
        navigate('/');
    }

    return (
        <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
            <div className="sidebar-header">
                <Link to="/dashboard">
                    <img src={logoSm} alt="logo" className="header-logo" />
                </Link>
                <RiCloseLargeFill className="close-btn" onClick={toggleSidebar} />
            </div>
            <div className="sidebar-section">
                <h4 className="sidebar-section-title">Organization</h4>
                <ul>
                    <li>
                        <Link to="/dashboard">
                            <MdDashboard className="sidebar-icon" />
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/businesses">
                            <IoMdBusiness className="sidebar-icon" />
                            List Businesses
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/rewards-history">
                            <TbHistory className="sidebar-icon" />
                            Rewards and History
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="sidebar-section">
                <h4 className="sidebar-section-title">Other</h4>
                <ul>
                    <li>
                        <Link to="/dashboard/profile-update">
                            <CgProfile className="sidebar-icon" />
                            Manage Profile
                        </Link>
                    </li>
                    <li>
                        <a href="/#" onClick={(e) => logOut(e)}>
                            <IoMdLogOut className="sidebar-icon" />
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;