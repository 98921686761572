import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import BlankProfilePicture from "../../assets/images/blank-profile-picture.png";
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { UpdateUserLoginDetailsService } from "../../services/user";
import "./UserProfileUpdate.css";

const UserProfileUpdate = () => {
    const [currentUser, tempUserLoginDetails] = useOutletContext();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({});

    useEffect(() => {
        (async () => {
            setLoading(true);
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails){
                    setUserLoginDetails(tempUserLoginDetails);
                }
                if (tempUserLoginDetails.name) {
                    setName(tempUserLoginDetails.name);
                }
                if (tempUserLoginDetails.email) {
                    setEmail(tempUserLoginDetails.email);
                }
                if (tempUserLoginDetails.mobile) {
                    setMobile(tempUserLoginDetails.mobile);
                }
                if (tempUserLoginDetails.image) {
                    setProfilePicture(tempUserLoginDetails.image);
                }
            };
            fetchUserLoginDetails();
            setLoading(false);
        })();
    }, [currentUser, tempUserLoginDetails]);

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            convertFileToBase64(file).then(base64Data => {
                setProfilePicture(base64Data);
            });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setUpdateLoading(true);
        await UpdateUserLoginDetailsService(currentUser, { "name": name, "email": email, "image": profilePicture });
        setUpdateLoading(false);
    };

    return (
        <Layout title="Update Profile" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="user-profile-update">
                    <h2 className="mob-page-title">Update Profile</h2>
                    <form onSubmit={handleUpdate} className="form">
                        <div className="profile-picture-container">
                            <span className="dx-label">Profile Picture:</span>
                            <img
                                src={profilePicture ? `data:image/png;base64, ${profilePicture}` : BlankProfilePicture}
                                alt="Profile"
                                className="profile-picture"
                            />
                            <label className="edit-icon">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProfilePictureChange}
                                    style={{ display: "none" }}
                                />
                                <span className="upload-btn">Change</span>
                            </label>
                        </div>
                        <label style={{ height: "45px" }}>
                            Mobile: {mobile}
                        </label>
                        <label>
                            Name:
                            <input
                                type="text"
                                className="dx-lib-input"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                className="dx-lib-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>
                        <button type="submit" className="submit-button dx-btn-primary" disabled={updateLoading}>
                            {updateLoading ? "Updating ..." : "Update"}
                        </button>
                    </form>
                </div>
            )}
        </Layout>
    );
};

export default UserProfileUpdate;