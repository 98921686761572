import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
import ForgotPasswordValidate from './pages/Auth/ForgotPasswordValidate/ForgotPasswordValidate';
import OtpValidation from './pages/Auth/OtpValidation/OtpValidation';
import UserLogin from './pages/Auth/UserLogin/UserLogin';
import Dashboard from './pages/Dashboard';
import ListBusinesses from './pages/ListBusinesses/ListBusinesses';
import RewardsAndHistory from './pages/RewardsAndHistory/RewardsAndHistory';
import RewardsAndHistoryComment from './pages/RewardsAndHistoryComment/RewardsAndHistoryComment';
import UserProfileUpdate from './pages/UserProfileUpdate/UserProfileUpdate';
import PrivateRoute from './routes/PrivateRoute';

function App() {
    return (
        <Router>
            <main className="container-main">
                <Routes>
                    <Route path="*" element={<Navigate to='/' replace />} />
                    <Route path="/" element={<UserLogin />} />
                    <Route path="/login" element={<UserLogin />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/forgot-password/otp" element={<OtpValidation />} />
                    <Route path="/forgot-password/validate" element={<ForgotPasswordValidate />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/profile-update" element={<UserProfileUpdate />} />
                        <Route path="/dashboard/businesses" element={<ListBusinesses />} />
                        <Route path="/dashboard/rewards-history" element={<RewardsAndHistory />} />
                        <Route path="/dashboard/rewards-history/comments/:history_token" element={<RewardsAndHistoryComment />} />
                    </Route>
                </Routes>
            </main>
        </Router>
    );
}

export default App;