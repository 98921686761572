import authBanner from "../../assets/images/auth-banner.png";
import "./auth-common.css";

const Layout = ({ children }) => {
    return (
        <div className="signup-container auth-container-main">
            <div className="wrapper-side">
                <img src={authBanner} alt="signup" className="auth-banner-img" />
            </div>
            <div className="wrapper-side">
                {children}
            </div>
        </div>
    );
};

export default Layout;