import React, { useEffect, useState } from "react";
import { BsChatLeftQuote } from "react-icons/bs";
import { PiCoinsLight } from "react-icons/pi";
import QRCode from "react-qr-code";
import { Link, useOutletContext } from "react-router-dom";
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { ReadBusinessesService } from "../../services/business";
import { ReadLoyaltyPointsHistoryService, ReadLoyaltyPointsService } from "../../services/loyalty";
import "./dashboard.css";

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [businesses, setBusinesses] = useState([]);
    const [currentLoyaltyPoints, setCurrentLoyaltyPoints] = useState(0);
    const [loyaltyHistory, setLoyaltyHistory] = useState([]);
    const [currentUser, tempUserLoginDetails] = useOutletContext();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails) {
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
            let temp_businesses = await ReadBusinessesService(currentUser, 'desc', 10, 0, '');
            if (temp_businesses.businesses) {
                setBusinesses(temp_businesses.businesses);
            }
            let temp_current_loyalty_points = await ReadLoyaltyPointsService(currentUser);
            if (temp_current_loyalty_points) {
                setCurrentLoyaltyPoints(temp_current_loyalty_points);
            }
            let temp_loyalty_history = await ReadLoyaltyPointsHistoryService(currentUser, 'desc', 5, 0, '');
            if (temp_loyalty_history.point_history) {
                setLoyaltyHistory(temp_loyalty_history.point_history);
            }
            setLoading(false);
        })();
    }, [currentUser, tempUserLoginDetails]);

    return (
        <Layout title="Dashboard" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboard-grid-container">
                    <div className="grid-child list-wrapper-1">
                        <h3 className="dx-table-title">
                            Business List{" "}
                            <Link to="/dashboard/businesses" className="btn-link view-all">
                                View All
                            </Link>
                        </h3>
                        <div className="table-desc-text">Recent 10 businesses.</div>
                        <div className="dx-table dashboard-table">
                            <div className="dx-table-responsive">
                                <div className="dx-tr table-header">
                                    <div className="dx-th">Business name</div>
                                </div>
                                {businesses.map((business) => {
                                    return (
                                        <div key={business.id} className="dx-tr table-data">
                                            <div className="dx-td">{business.name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-2">
                        <h3 className="dx-table-title">Current Rewards Points</h3>
                        <div className="points-wrapper">
                            <PiCoinsLight />
                            {currentLoyaltyPoints}
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-3">
                        <h3 className="dx-table-title">
                            Transaction History
                            <Link
                                to="/dashboard/rewards-history"
                                className="btn-link view-all"
                            >
                                View All
                            </Link>
                        </h3>
                        <div className="table-desc-text">Last 5 transactions</div>
                        <div className="dx-table dashboard-table">
                            <div className="dx-table-responsive">
                                <div className="dx-tr table-header">
                                    <div className="dx-th">Date</div>
                                    <div className="dx-th">Type</div>
                                    <div className="dx-th">Business</div>
                                    <div className="dx-th">Points</div>
                                    <div className="dx-th"></div>
                                </div>
                                {loyaltyHistory.map((history) => {
                                    return (
                                        <div key={history.id} className="dx-tr table-data">
                                            <div className="dx-td">{history.create_date}</div>
                                            <div className="dx-td">{history.type === 'credit' ? 'Credit' : 'Debit'}</div>
                                            <div className="dx-td">{history.business}</div>
                                            <div className="dx-td">{history.points}</div>
                                            <div className="dx-td">
                                                {history.access_token && !history.is_offer_given ?
                                                    <Link to={`/dashboard/rewards-history/comments/${history.access_token}`}
                                                        className="btn-link comments-btn" title="Comments">
                                                        <BsChatLeftQuote />
                                                    </Link> : ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-4">
                        <div
                            style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: "200px",
                                width: "100%",
                                background: "#fff",
                                padding: 10,
                                borderRadius: 10,
                            }}
                        >
                            {userLoginDetails && userLoginDetails.mobile ? (
                                <QRCode
                                    size={656}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={userLoginDetails.mobile}
                                    viewBox={`0 0 256 256`}
                                />
                            ) : ''}
                        </div>
                        <p className="qr-sub-title">
                            {userLoginDetails && userLoginDetails.name ? userLoginDetails.name : ''}
                        </p>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Dashboard;