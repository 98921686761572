import React, { useEffect, useState } from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import Sidebar from "./Sidebar";

const Layout = ({ children, title, userLoginDetails }) => {
    const [isOpen, setIsOpen] = useState(() => {
        return JSON.parse(localStorage.getItem("isSidebarOpen")) || false;
    });

    const toggleSidebar = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        localStorage.setItem("isSidebarOpen", JSON.stringify(newIsOpen));
    };

    useEffect(() => {
        const handleResize = () => {
            const savedState = JSON.parse(localStorage.getItem("isSidebarOpen"));
            if (window.innerWidth > 768 && savedState !== true) {
                setIsOpen(true);
                localStorage.setItem("isSidebarOpen", JSON.stringify(true));
            } else if (window.innerWidth <= 768 && savedState !== false) {
                setIsOpen(false);
                localStorage.setItem("isSidebarOpen", JSON.stringify(false));
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <MobileHeader toggleSidebar={toggleSidebar} />
            <DesktopHeader title={title} userLoginDetails={userLoginDetails}/>
            <div>
                <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            </div>
            <div className="content-main-container">{children}</div>
        </>
    );
};

export default Layout;