import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { AddLoyaltyPointsHistoryCommentsService, ReadLoyaltyPointsHistoryCommentsService } from "../../services/loyalty";
import "./RewardsAndHistoryComment.css";

const RewardsAndHistoryComment = () => {
    const [formData, setFormData] = useState({
        stars: 0,
        comment: "",
    });
    const [formErrors, setFormErrors] = useState({
        comment: ""
    });
    const [rating, setRating] = useState(0);
    const [ratingFilled, setRatingFilled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addCommentLoading, setAddCommentLoading] = useState(false);
    const [loyaltyComments, setLoyaltyComments] = useState([]);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [currentUser, tempUserLoginDetails] = useOutletContext();
    const params = useParams();

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        value = value.trim();
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let temp_loyalty_history_comments = await ReadLoyaltyPointsHistoryCommentsService(currentUser, params.history_token);
            if (temp_loyalty_history_comments.comments) {
                setLoyaltyComments(temp_loyalty_history_comments.comments);
            }
            if (temp_loyalty_history_comments.rating > 0){
                setRatingFilled(true);
                setRating(temp_loyalty_history_comments.rating);
            }
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails) {
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
            setLoading(false);
        })();
    }, [currentUser, tempUserLoginDetails, params.history_token]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const commentError = formData.comment ? "" : "Comment should not be Empty";
        setFormErrors({
            comment: commentError,
        });
        if (!commentError) {
            setAddCommentLoading(true);
            let add_comment_response = await AddLoyaltyPointsHistoryCommentsService(currentUser, params.history_token, !ratingFilled ? formData.stars : 0, formData.comment);
            if (!add_comment_response.success) {
                setFormErrors({
                    comment: add_comment_response.message,
                });
            }
            setAddCommentLoading(false);
            setFormData({
                ...formData,
                'comment': '',
            });
            setLoading(true);
            let temp_loyalty_history_comments = await ReadLoyaltyPointsHistoryCommentsService(currentUser, params.history_token);
            if (temp_loyalty_history_comments.comments) {
                setLoyaltyComments(temp_loyalty_history_comments.comments);
            }
            if (temp_loyalty_history_comments.rating > 0){
                setRatingFilled(true);
                setRating(temp_loyalty_history_comments.rating);
            }
            setLoading(false);
        }
    };

    const handleRating = (n) => {
        setRating(n);
        setFormData({
            ...formData,
            'stars': n,
        });
    };

    return (
        <Layout title="Rewards Points and Transactions History Comments" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="reward-history-comments">
                    <div className="comment-section">
                        <form onSubmit={handleUpdate} className="form">
                            <div className="star-rating">
                                <div className="stars">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <span
                                            key={star}
                                            onClick={() => ratingFilled ? '' : handleRating(star)}
                                            className={`star ${rating >= star ? 'filled' : ''}`}>
                                            <label />
                                        </span>
                                    ))}
                                </div>
                                <div className="stars-output">Rating is: {rating} / 5</div>
                            </div>
                            <textarea className="dx-lib-input" name="comment" placeholder="Write a message..."
                                onChange={handleInputChange} defaultValue={formData.comment}></textarea>
                            {formErrors.comment && (
                                <p className="dx-lib input-error">{formErrors.comment}</p>
                            )}
                            <button type="submit" className="submit-button dx-btn-primary" disabled={addCommentLoading}>
                                {addCommentLoading ? "Sending ..." : "Send"}
                            </button>
                        </form>
                    </div>
                    <div className="all-comments">
                        <div className="comments">
                            {loyaltyComments.map((comment) => {
                                return (
                                    <div key={comment.id} className="comment">
                                        <div className="child-comment">
                                            <img src={`data:image/jpeg;base64,${comment.author_avatar}`} alt="Avatar" className="avatar" />
                                            <div className="comment-content">
                                                <div className="comment-author">{comment.author}</div>
                                                <div className="comment-date">Published on {comment.date}</div>
                                                <div className="comment-text">{parse(comment.body)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default RewardsAndHistoryComment;