import React from "react";
import { Link } from "react-router-dom";
import BlankProfilePic from "../../assets/images/blank-profile-picture.png";

const DesktopHeader = ({ title, userLoginDetails }) => {
    return (
        <div className="desktop-header-container">
            <div className="items left">
                <div className="current-page-title">{title}</div>
            </div>
            <div className="items right">
                <div className="profile-pic-sm-w">
                    <Link to="/dashboard/profile-update">
                        {userLoginDetails && userLoginDetails.image ? (
                            <img src={`data:image/jpeg;base64,${userLoginDetails.image}`} alt="profile" className="header-logo" />
                        ) : (
                            <img src={BlankProfilePic} alt="profile" className="header-logo" />
                        )}
                    </Link>
                </div>
                <div className="desktop-header-user-name">
                    <Link to="/dashboard/profile-update">
                        {userLoginDetails && userLoginDetails.name ? userLoginDetails.name : ''}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default DesktopHeader;