import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoSm from "../../../assets/icons/logo-1.jpg";
import { GenerateOTPResetPasswordService } from "../../../services/user";
import "../auth-common.css";

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        mobile: "",
    });
    const [formErrors, setFormErrors] = useState({
        mobile: ""
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let temp_generate_otp = await GenerateOTPResetPasswordService(formData.mobile);
        if (temp_generate_otp.success) {
            navigate("/forgot-password/otp", { state: { mobile: formData.mobile } });
        } else {
            setFormErrors({
                mobile: temp_generate_otp.message,
            });
        }
        setLoading(false);
    };

    return (
        <div className="forgot-pswd-container auth-container-main">
            <div className="wrapper-side form-container">
                <div className="form-container">
                    <div className="logo-wrapper">
                        <img src={logoSm} alt="" />
                    </div>
                    <h1 className="auth-title" >Reset Your Password</h1>
                    <form onSubmit={handleSubmit} className="auth-form signup" >
                        <div className="form-group-dx">
                            <label htmlFor="mobile">Please enter your valid mobile to get OTP.</label>
                            <input
                                type="text"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                placeholder="Enter mobile number"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        {formErrors.mobile && (
                            <p className="dx-lib input-error">{formErrors.mobile}</p>
                        )}
                        <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                            {loading ? "Generating OTP..." : "Generate OTP"}
                        </button>
                    </form>
                    <p className="account-exist-check">
                        Remember password? <Link to="/">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;