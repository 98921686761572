import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Loading from "../components/common/Loading";
import { UserLoginDetailsService } from "../services/user";

const PrivateRoute = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { currentUser } = useSelector(state => state.user);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    const validateToken = (currentUser) => {
        return async (dispatch) => {
            try {
                let temp_user_login_details = await UserLoginDetailsService(currentUser);
                if (temp_user_login_details && 'id' in temp_user_login_details && temp_user_login_details.id) {
                    setUserLoginDetails(temp_user_login_details);
                    setIsAuthenticated(true);
                    dispatch({ type: 'VALIDATE_TOKEN_SUCCESS', payload: currentUser });
                } else {
                    dispatch({ type: 'VALIDATE_TOKEN_FAILURE' });
                    setIsAuthenticated(false);
                }
            } catch (error) {
                dispatch({ type: 'VALIDATE_TOKEN_ERROR', error });
                setIsAuthenticated(false);
            }
        };
    };

    useEffect(() => {
        currentUser ? dispatch(validateToken(currentUser)).finally(() => setLoading(false)) : setLoading(false);
    }, [dispatch, currentUser, location]);

    if (loading) {
        return <Loading />;
    }

    return isAuthenticated ? <Outlet context={[currentUser, userLoginDetails]} /> : <Navigate to="/login" />;
};

export default PrivateRoute;