import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoSm from "../../../assets/icons/logo-1.jpg";
import { signInFailure, signInStart, signInSuccess } from "../../../redux/user/userSlice";
import { UserLoginService } from "../../../services/user";
import "../auth-common.css";
import Layout from "../Layout";

const UserLogin = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [formData, setFormData] = useState({
        mobile: "",
        password: "",
    });
    const [formErrors, setFormErrors] = useState({
        mobile: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordError = validatePassword(formData.password)
            ? ""
            : "Password must be at least 8 characters long and contain letters, symbols and numbers";

        setFormErrors({
            password: passwordError,
        });

        if (!passwordError) {
            setLoading(true);
            dispatch(signInStart());
            let temp_user_login_data = await UserLoginService(formData.mobile, formData.password);
            if (!temp_user_login_data.success) {
                dispatch(signInFailure(temp_user_login_data));
                setFormErrors((prev) => ({ ...prev, password: temp_user_login_data.message }));
                setLoading(false);
                return;
            } else {
                dispatch(signInSuccess(temp_user_login_data.data.access_token));
                navigate("/dashboard");
                setLoading(false);
                return;
            }
        }
    };

    return (
        <Layout>
            <div className="form-container">
                <div className="logo-wrapper">
                    <img src={logoSm} alt="" />
                </div>
                <h1 className="auth-title">Login to your account</h1>
                <form onSubmit={handleSubmit} className="auth-form signup">
                    <div className="form-group-dx">
                        <label htmlFor="mobile">Mobile Number</label>
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            placeholder="Enter mobile number"
                            onChange={handleInputChange}
                            required
                        />
                        {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
                    </div>
                    <div className="form-group-dx">
                        <span className="password-label-wrapper">
                            <label htmlFor="password">Password</label>
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                            >
                                {passwordVisible ? "Hide" : "Show"}
                            </button>
                        </span>
                        <div className="password-container">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                placeholder="Enter password"
                                onChange={handleInputChange}
                                required
                            />
                            <div className="forgor-psw-wrapper">
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </div>
                        </div>
                        <p className="password-hint">
                            Use 8 or more characters with a mix of letters, numbers & symbols
                        </p>
                    </div>
                    {formErrors.password && (
                        <p className="dx-lib input-error">{formErrors.password}</p>
                    )}
                    <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                        {loading ? "Logging in..." : "Login"}
                    </button>
                </form>
            </div>
        </Layout>
    );
};

export default UserLogin;