import React from 'react';
import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import logoSm from "../../assets/icons/logo-1.jpg";

const MobileHeader = ({ toggleSidebar }) => {
    return (
        <div className="mobile-header-container">
            <div className="items left">
                <div className="ham-menu">
                    <button className="dx-btn dx-close-btn" onClick={toggleSidebar}  >
                        <HiMenuAlt1 />
                    </button>
                </div>
                <div className="logo-wrapper">
                    <Link to="/dashboard">
                        <img src={logoSm} alt="logo" className="header-logo" />
                    </Link>
                </div>
            </div>
            <div className="items right">

            </div>
        </div>
    )
}

export default MobileHeader;