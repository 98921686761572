import axios from "axios";
import config from "../config";

export const ReadLoyaltyPointsService = async (currentUser) => {
    var loyalty_points = 0;
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .get("user/loyalty/points/", {
            headers: {
                "authorization" : "Bearer " + currentUser,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.success){
                loyalty_points = response.data.data.points;
            }
        })
        .catch((error) => {
            
        });

    return await loyalty_points;
};

export const ReadLoyaltyPointsHistoryService = async (currentUser, direction, limit, page, search) => {
    var loyalty_history_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .get("user/loyalty/history/", {
            params: {
                'direction': direction,
                'limit': limit,
                'page': page,
                'search': search
            },
            headers: {
                "authorization" : "Bearer " + currentUser,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.success){
                loyalty_history_data['point_history'] = response.data.data.point_history;
                loyalty_history_data['total_point_history'] = response.data.data.total_point_history;
            }
        })
        .catch((error) => {
        });

    return await loyalty_history_data;
};

export const ReadLoyaltyPointsHistoryCommentsService = async (currentUser, historyAccessToken) => {
    var loyalty_history_comments_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .get("user/loyalty/history/review/", {
            params: {
                'history_access_token': historyAccessToken
            },
            headers: {
                "authorization" : "Bearer " + currentUser,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.success){
                loyalty_history_comments_data = response.data.data;
            }
        })
        .catch((error) => {
        });

    return await loyalty_history_comments_data;
};

export const AddLoyaltyPointsHistoryCommentsService = async (currentUser, historyAccessToken, feedbackStars, feedbackComment) => {
    var comment_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .put("user/loyalty/history/review/", {
            'history_access_token': historyAccessToken,
            'feedback_stars': feedbackStars,
            'feedback_comment': feedbackComment
        },
        {
            headers: {
                "authorization": "Bearer " + currentUser,
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            comment_response = response.data;
        })
        .catch((error) => {
            comment_response = error.response.data;
        });

    return await comment_response;
};