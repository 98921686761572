import axios from "axios";
import config from "../config";

export const ReadBusinessesService = async (currentUser, direction, limit, page, search) => {
    var businesses_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .get("business/", {
            params: {
                'direction': direction,
                'limit': limit,
                'page': page,
                'search': search
            },
            headers: {
                "authorization" : "Bearer " + currentUser,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.success){
                businesses_data['businesses'] = response.data.data.businesses;
                businesses_data['total_businesses'] = response.data.data.total_businesses;
            }
        })
        .catch((error) => {
        });

    return await businesses_data;
};