import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useOutletContext } from "react-router-dom";
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { ReadBusinessesService } from "../../services/business";

const ITEMS_PER_PAGE = 5;

const BusinessTable = () => {
    const [loading, setLoading] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [businesses, setBusinesses] = useState([]);
    const [totalBusinesses, setTotalBusinesses] = useState(0);
    const [totalBusinessesCount, setTotalBusinessesCount] = useState(0);
    const [businessSearchValue, setBusinessSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [currentUser, tempUserLoginDetails] = useOutletContext();

    const fetchBusinesses = async (page) => {
        setLoading(true);
        const response = await ReadBusinessesService(currentUser, 'asc', ITEMS_PER_PAGE, page, businessSearchValue);
        if (response.businesses) {
            setBusinesses(response.businesses);
        }
        setTotalBusinesses(Math.ceil(response.total_businesses / ITEMS_PER_PAGE));
        setTotalBusinessesCount(response.total_businesses);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage) => {
        fetchBusinesses(newPerPage);
        setCurrentPage(newPerPage);
    };

    const setBusinessSearchValueCallback = async (e) => {
        setBusinessSearchValue(e.target.value);
    }

    const searchBusinesses = async (e) => {
        e.preventDefault();
        fetchBusinesses(currentPage);
    }

    const clearSearchedBusinesses = async (e) => {
        e.preventDefault();
        setBusinessSearchValue("");
        fetchBusinesses(currentPage);
    }

    useEffect(() => {
        (async () => {
            const fetchBusinesses = async () => {
                setLoading(true);
                const response = await ReadBusinessesService(currentUser, 'asc', ITEMS_PER_PAGE, 0, '');
                if (response.businesses) {
                    setBusinesses(response.businesses);
                }
                setTotalBusinesses(Math.ceil(response.total_businesses / ITEMS_PER_PAGE));
                setTotalBusinessesCount(response.total_businesses);
                setLoading(false);
            };
            fetchBusinesses();
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails){
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
        })();
    }, [currentUser, tempUserLoginDetails]);

    const tableHead = ["Business"];

    return (
        <Layout title="Business List" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="business-table-container">
                    <div className="searchbox">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search with Name ..."
                            value={businessSearchValue}
                            onChange={setBusinessSearchValueCallback}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    searchBusinesses(e)
                                }
                            }}
                        />
                        <a
                            href="/#"
                            onClick={(e) =>
                                searchBusinesses(e)
                            }>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.9984 20.9984L16.6484 16.6484"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                        {businessSearchValue ? (
                            <a
                                href="/#"
                                className="close-icon"
                                onClick={(e) =>
                                    clearSearchedBusinesses(e)
                                }>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none">
                                    <path
                                        d="M26 2L2 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2 2L26 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>) : ("")
                        }
                    </div>
                    <table className="business-table">
                        <thead className="table-header">
                            <tr className="header-row">
                                {
                                    tableHead.map((names, id) => {
                                        return <th key={id} className="header-cell">{names}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {businesses.map((item) => (
                                <tr key={item.id} className="table-row">
                                    <td className="table-cell">{item.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalBusinessesCount > ITEMS_PER_PAGE ? (
                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage - 1)
                                }
                                disabled={currentPage === 0}
                            >
                                <IoIosArrowBack />
                            </button>
                            <span className="pagination-info">
                                Page {currentPage + 1} of {totalBusinesses}
                            </span>
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage + 1)
                                }
                                disabled={currentPage + 1 === totalBusinesses}
                            >
                                <IoIosArrowForward />
                            </button>
                        </div>
                    ) : ""}
                </div>
            )}
        </Layout>

    );
};

export default BusinessTable;